<template>
  <div>
    <window-header></window-header>
    <nav-form :onlyIcons="true"></nav-form>
    <section>
      <article style="height: 96px; width:411px">
        <field
          widget="image"
          name="imagen"
          style="position:absolute;width:85px; height:85px; top: 4px; left:6px;"
        />
        <field
          name="codigo"
          widget="input"
          searchable
          label="Código"
          placeholder="Cód."
          width="67px"
          style="top:9px; left:100px;"
          :readonly="mode == 'edit'"
          inputStyle="font-weight:bold;text-align:center;"
        />
        <field
          name="descripcion"
          widget="input"
          searchable
          label="Naturaleza"
          help="Descripcion"
          placeholder="Descripcion"
          width="270px"
          style="top:50px; left:100px;"
        />
      </article>
    </section>
    <v-collapse-wrapper ref="main-collapse" :active="true">
      <div class="header" v-collapse-toggle>
        <div class="item-title">Listado</div>
        <div class="item-after">{{count}}</div>
      </div>
      <div class="my-content" v-collapse-content>
        <hot-table ref="hotTableComponent" :settings="htSettings"></hot-table>
      </div>
    </v-collapse-wrapper>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import rTableMixin from "./../components/rTableMixin.vue";

export default {
  mixins: [WindowMixin, rFormMixin, rTableMixin],
  data: function() {
    return {
      title: "Naturaleza",
      dbAdapter: "naturaleza",
      primary: "codigo",
      sequence: { name: "naturaleza" }
    };
  },
  mounted: function() {
    //var $ = window.$;
  }
};
</script>
